/**
 * @fileoverview Dashboard page outer level component
 *
 */

import React, { useEffect } from "react";
import { useState } from "react";
import CostBenefitChart from "../components/dashboard/CostBenefitChart";
import CostAndSavings from "../components/dashboard/TotalCostsGraph";
import GHGReductionsGraph from "../components/dashboard/GHGReductionsGraph";
import TimelineGraph from "../components/dashboard/TimelineGraph";
import PortCounts from "../components/dashboard/PortCounts";
import CashFlow from "../components/dashboard/CashFlow";
import CapitalCostsGraph from "../components/dashboard/CapitalCostsGraph";
import LoadingSpinner from "../components/ui/LoadingSpinner";
import useProForma from "../store/useProForma";
import ElectrificationScenarioDropdown from "../components/dashboard/ElectrificationScenarioDropdown";
import SiteDropdown from "../components/dashboard/SiteDropdown";
import AdvControlsPopup from "../components/advanced-controls/AdvControlsPopup";
import YearDropdown from "components/dashboard/YearDropdown";
import ToggleNav from "components/ToggleNav";
import { useMediaQuery } from "react-responsive";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import SortableGraphWrapper from "../components/dashboard/SortableGraphWrapper";

const isEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

function Dashboard() {
  const { controls, cityInfo } = useProForma();
  const [renderKey, setRenderKey] = useState(0);

  const [isScrolled, setIsScrolled] = useState(false);

  // Define default graph order
  const defaultGraphs = [
    { id: 'cost-benefit', component: <CostBenefitChart /> },
    { id: 'ghg-reductions', component: <GHGReductionsGraph /> },
    { id: 'capital-costs', component: <CapitalCostsGraph /> },
    { id: 'timeline', component: <TimelineGraph /> },
  ];

  // Load saved order from localStorage or use default
  const [graphs, setGraphs] = useState(() => {
    const savedOrder = localStorage.getItem('dashboardGraphOrder');
    if (savedOrder) {
      const savedIds = JSON.parse(savedOrder);
      // Reconstruct graphs array using saved order
      return savedIds.map(id => 
        defaultGraphs.find(graph => graph.id === id)
      );
    }
    return defaultGraphs;
  });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setGraphs((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        const newOrder = arrayMove(items, oldIndex, newIndex);
        
        // Save new order to localStorage
        const orderIds = newOrder.map(item => item.id);
        localStorage.setItem('dashboardGraphOrder', JSON.stringify(orderIds));
        
        return newOrder;
      });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1023px)" });

  return (
    <div className="relative bg-background_gray w-full overflow-visible">
      <div
        style={{ width: "inherit", minWidth: "inherit" }}
        className={`flex flex-col items-start bg-background_gray z-40 h-min-[100px] p-[20px] box-border w-full sticky top-0 max-w-screen ${
          isEmpty(controls) ? "invisible" : ""
        } ${isScrolled ? "shadow-lg" : ""}`}
      >
        <div className="flex justify-between gap-2">
          <ToggleNav />
        </div>
        <div className="w-[85%] flex gap-2 lg:flex-col">
          <div className="flex gap-2" style={{ maxWidth: "33%" }}>
            <SiteDropdown />
          </div>

          <div className="flex gap-2" style={{ maxWidth: "50%" }}>
            <div className="text-[#b0b3af] text-xl lg:hidden font-medium font-['Inter'] leading-normal">
              for
            </div>

            <div className="inline-block w-11/12">
              <ElectrificationScenarioDropdown />
            </div>
          </div>
          <div className="flex gap-2 flex-1">
            <div className="lg:hidden text-[#b0b3af] text-xl font-medium font-['Inter'] leading-normal">
              in
            </div>
            <YearDropdown />
          </div>
        </div>

        <AdvControlsPopup />
      </div>

      {isEmpty(controls) && (
        <div className="w-full h-full flex justify-center items-center z-20">
          <LoadingSpinner />
        </div>
      )}

      <div className={` ${isEmpty(controls) ? "hidden" : ""}`}>
        <div className="flex lg:flex-col-reverse rounded-3xl gap-6 bg-gray2 p-6 shadow-lg">
          <div className="flex-[2.2_2.2_0%] flex flex-col gap-4 flex-start">
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext items={graphs.map(graph => graph.id)} strategy={verticalListSortingStrategy}>
                {graphs.map((graph) => (
                  <SortableGraphWrapper key={graph.id} id={graph.id}>
                    {graph.component}
                  </SortableGraphWrapper>
                ))}
              </SortableContext>
            </DndContext>
          </div>
          <div className="flex-[1_1_0%] flex flex-col gap-4">
            <div className="">
              <CashFlow />
            </div>
            <div className="">
              <CostAndSavings key={renderKey} />
            </div>
            <div className="">
              <PortCounts />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
