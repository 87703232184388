/**
 * This is the component for the capital costs graph displayed in the dashboard.
 *
 * Parent: Dashboard.js
 */

import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  ReferenceLine,
} from "recharts";
import useProForma from "store/useProForma";
import { axisHelper } from "utils/graphs/axisHelper";
import { axisStyle, labelStyle } from "./styles";
import { roundToNearest } from "utils/graphs/roundNumbers";
import useGraphState from "store/useGraphState";
import { useMediaQuery } from "react-responsive";
import { PRIMARY_COLOR, SECONDARY_GRAPH_COLOR } from "colors";
import { CustomTooltip } from "components/dashboard/graphs/CustomTooltip";
import useHandleEvalYearUpdate from "components/hooks/useHandleUpdateEvalYear.js";

// Create lighter shades of primary and secondary colors
const getLighterColor = (hexColor) => {
  // Convert hex to RGB
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  // Lighten by mixing with white
  const lighterR = Math.round(r + (255 - r) * 0.4);
  const lighterG = Math.round(g + (255 - g) * 0.4);
  const lighterB = Math.round(b + (255 - b) * 0.4);

  // Convert back to hex
  return `#${lighterR.toString(16).padStart(2, "0")}${lighterG
    .toString(16)
    .padStart(2, "0")}${lighterB.toString(16).padStart(2, "0")}`;
};

const LIGHTER_PRIMARY_COLOR = getLighterColor(PRIMARY_COLOR);
const LIGHTER_SECONDARY_COLOR = getLighterColor(SECONDARY_GRAPH_COLOR);

const roundedValue = (value, up = true) => {
  return up
    ? Math.ceil((value * 1.2) / 100000) * 100000
    : Math.floor((value * 1.2) / 100000) * 100000;
};

const transformData = (yearOverYear, allSitesYearOverYear) => {
  const data = [];
  const {
    costOfElectricVehicles = {},
    chargerPurchaseCosts = {},
    chargerInstallCosts = {},
    trenchingCosts = {},
    upgradeCostUtility = {},
    upgradeCostCustomer = {},
    procurementManagementCost = {},
    estimatedPublicWorksEngineeringCosts = {},
    otherCosts = {},
  } = yearOverYear;

  const {
    costOfElectricVehicles: allSitesCostOfElectricVehicles = {},
    chargerPurchaseCosts: allSitesChargerPurchaseCosts = {},
    chargerInstallCosts: allSitesChargerInstallCosts = {},
    trenchingCosts: allSitesTrenchingCosts = {},
    upgradeCostUtility: allSitesUpgradeCostUtility = {},
    upgradeCostCustomer: allSitesUpgradeCostCustomer = {},
    procurementManagementCost: allSitesProcurementManagementCost = {},
    estimatedPublicWorksEngineeringCosts:
      allSitesEstimatedPublicWorksEngineeringCosts = {},
    otherCosts: allSitesOtherCosts = {},
  } = allSitesYearOverYear;

  Object.keys(costOfElectricVehicles).forEach((year) => {
    const evseCosts =
      (chargerPurchaseCosts[year] || 0) +
      (chargerInstallCosts[year] || 0) +
      (trenchingCosts[year] || 0) +
      (upgradeCostUtility[year] || 0) +
      (upgradeCostCustomer[year] || 0) +
      (procurementManagementCost[year] || 0) +
      (estimatedPublicWorksEngineeringCosts[year] || 0) +
      (otherCosts[year] || 0);

    const allSitesEvseCosts =
      (allSitesChargerPurchaseCosts[year] || 0) +
      (allSitesChargerInstallCosts[year] || 0) +
      (allSitesTrenchingCosts[year] || 0) +
      (allSitesUpgradeCostUtility[year] || 0) +
      (allSitesUpgradeCostCustomer[year] || 0) +
      (allSitesProcurementManagementCost[year] || 0) +
      (allSitesEstimatedPublicWorksEngineeringCosts[year] || 0) +
      (allSitesOtherCosts[year] || 0);

    data.push({
      year: parseInt(year, 10),
      evCosts: costOfElectricVehicles[year] || 0,
      evseCosts: evseCosts,
      allSitesEvCosts: allSitesCostOfElectricVehicles[year] || 0,
      allSitesEvseCosts: allSitesEvseCosts,
    });
  });

  return data;
};

const formatAsCurrency = (value) => {
  let roundedValue;
  let suffix;

  if (Math.abs(value) >= 1000000) {
    roundedValue = Math.round(value / 1000000);
    suffix = "M";
  } else if (Math.abs(value) >= 1000) {
    roundedValue = Math.round(value / 1000);
    suffix = "k";
  } else {
    roundedValue = Math.round(value);
    suffix = "";
  }

  const absValue = Math.abs(roundedValue)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return value < 0 ? `-$${absValue}${suffix}` : `$${absValue}${suffix}`;
};

const sumValues = (data) => {
  let sum = 0;
  for (const year in data) {
    if (year === "title") {
      continue;
    }
    sum += data[year];
  }
  return sum;
};

const CapitalCostsGraph = () => {
  const { yearHovered } = useGraphState();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1023px)" });
  const handleClick = useHandleEvalYearUpdate();
  const { yearOverYear, controls, allSitesYearOverYear, cityInfo, years } =
    useProForma();
  const { EVAL_YEAR } = years;
  const { totalCapitalCosts: yearOverYearTotalCapitalCosts = {} } =
    allSitesYearOverYear;
  const { totalCapitalCosts = {} } = yearOverYear;
  const data = transformData(yearOverYear, allSitesYearOverYear);
  //state variables
  const [maxVal, setMaxVal] = useState(0);
  const [showAllSites, setShowAllSites] = useState(true);

  useEffect(() => {
    if (controls.domiciles) {
      setShowAllSites(controls.domiciles.length > 1);
    }
  }, [controls]);

  useEffect(() => {
    let max = 0;
    Object.values(yearOverYearTotalCapitalCosts).forEach((value) => {
      if (value > max) {
        max = value;
      }
    });
    let maxRounded = roundedValue(max);

    setMaxVal(Math.max(maxRounded, cityInfo.capital_costs_max));
  }, [allSitesYearOverYear]);

  useEffect(() => {
    sumValues(totalCapitalCosts);
  }, [totalCapitalCosts]);

  return (
    <div className="bg-white rounded-2xl p-4 gap-4 flex flex-col h-[230px] lg:h-[300px] border">
      <div className="flex gap-2 text-[#1b1c1b] text-xl font-medium font-['Inter'] leading-normal">
        <img src="icons/capitalcostsicon.svg" width="18"></img>
        Capital Costs
      </div>
      <div className="flex lg:flex-col lg:gap-2">
        <div className="flex-none flex flex-col justify-start gap-4 w-[200px] lg:justify-start lg:w-3/4 lg:m-auto lg:flex-row">
          <div>
            <div className="flex items-end">
              <div className="text-[#1b1c1b] text-xl font-medium font-['Inter'] leading-normal">
                $
              </div>
              <div className="text-[#1b1c1b] text-[35px] font-semibold font-['Inter'] leading-[42px]">
                {roundToNearest(totalCapitalCosts[EVAL_YEAR]).val}
              </div>
              <div className="text-[#1b1c1b] text-xl font-medium font-['Inter'] leading-normal">
                {roundToNearest(totalCapitalCosts[EVAL_YEAR]).suffix}
              </div>
            </div>
            <div className="text-[#7e817d] text-[13px] font-normal font-['Roboto'] leading-none">
              Capital costs, {EVAL_YEAR}
            </div>
          </div>
          <div>
            <div className="flex items-end">
              <div className="text-[#1b1c1b] text-xl font-medium font-['Inter'] leading-normal">
                $
              </div>
              <div className="text-[#1b1c1b] text-[35px] font-semibold font-['Inter'] leading-[42px]">
                {roundToNearest(sumValues(totalCapitalCosts)).val}
              </div>
              <div className="text-[#1b1c1b] text-xl font-medium font-['Inter'] leading-normal">
                {roundToNearest(sumValues(totalCapitalCosts)).suffix}
              </div>
            </div>
            <div className="text-[#7e817d] text-[13px] font-normal font-['Roboto'] leading-none">
              Total Capital Costs
            </div>
          </div>
        </div>
        <div className="flex-grow lg:pt-2">
          <ResponsiveContainer className="" width="100%" height={160}>
            <BarChart onClick={handleClick} data={data} margin={{ left: 20 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                stroke="#7E817D"
                dataKey="year"
                style={axisStyle}
                axisLine={false}
              />
              <YAxis
                stroke="#7E817D"
                minTickGap={-10}
                type="number"
                domain={[0, maxVal]}
                ticks={axisHelper(0, maxVal, 4)}
                tickFormatter={formatAsCurrency}
                style={axisStyle}
                axisLine={false}
              >
                {!isTabletOrMobile && (
                  <Label
                    value="Total Costs ($)"
                    angle={-90}
                    position="left"
                    style={labelStyle}
                  />
                )}
              </YAxis>
              <Tooltip
                content={<CustomTooltip />}
                formatter={formatAsCurrency}
              />
              <Legend align="right" wrapperStyle={{ fontSize: "12px" }} />
              <ReferenceLine
                x={yearHovered ?? EVAL_YEAR}
                stroke={PRIMARY_COLOR}
              />
              {(controls.site === "All Sites" || showAllSites) && (
                <>
                  <Bar
                    radius={[5, 5, 5, 5]}
                    dataKey="allSitesEvCosts"
                    stackId="allSites"
                    fill={PRIMARY_COLOR}
                    name="All Sites EV Costs ($)"
                  />
                  <Bar
                    radius={[5, 5, 5, 5]}
                    dataKey="allSitesEvseCosts"
                    stackId="allSites"
                    fill={LIGHTER_PRIMARY_COLOR}
                    name="All Sites EVSE Costs ($)"
                  />
                </>
              )}
              {controls.site !== "All Sites" && (
                <>
                  <Bar
                    radius={[5, 5, 5, 5]}
                    dataKey="evCosts"
                    stackId="site"
                    fill={SECONDARY_GRAPH_COLOR}
                    name={`${controls.site} EV Costs ($)`}
                  />
                  <Bar
                    radius={[5, 5, 5, 5]}
                    dataKey="evseCosts"
                    stackId="site"
                    fill={LIGHTER_SECONDARY_COLOR}
                    name={`${controls.site} EVSE Costs ($)`}
                  />
                </>
              )}
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default CapitalCostsGraph;
