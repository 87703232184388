/**
 * @fileoverview
 * This component contains the form for updating the city information of the Set Info page.
 * Image, city name, graph maxxes and mins are set here.
 * 
 * 
 * Parent element: AdminSetInfo.js.js
 */



import React, { useState, useEffect } from "react";
import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import useProForma from "store/useProForma";
import useAuthStore from "store/useAuthStore";
import { useToast } from "components/hooks/use-toast";
import { Separator } from "components/ui/separator";



const CityInfoForm = () => {
  const { fetchAndUpdateFleet,cityInfo } = useProForma();
  const { toast } = useToast();

  const [formData, setFormData] = useState({
    city_name: "",
    city_image: "",
    recovery_email: "",
    cost_benefit_min: null,
    cost_benefit_max: null,
    cost_savings_max: null,
    capital_costs_max: null,
    // year_min: null,
    year_max: null,
    range_1: "",
    range_2: "",
    range_3: "",
    range_4: "",
  });

  // Updates the form data when the cityInfo loads
  useEffect(() => {
    if (cityInfo) {
      setFormData({
        city_name: cityInfo.city_name ?? "",
        city_image: cityInfo.city_image ?? "",
        recovery_email: cityInfo.recovery_email ?? "",
        cost_benefit_min: cityInfo.cost_benefit_min ?? null,
        cost_benefit_max: cityInfo.cost_benefit_max ?? null,
        cost_savings_max: cityInfo.cost_savings_max ?? null,
        capital_costs_max: cityInfo.capital_costs_max ?? null,
        // year_min: cityInfo.year_min ?? null,
        year_max: cityInfo.year_max ?? null,
        range_1: cityInfo.range_1 ?? "",
        range_2: cityInfo.range_2 ?? "",
        range_3: cityInfo.range_3 ?? "",
        range_4: cityInfo.range_4 ?? "",
      });
    }
  }, [cityInfo]);

  // updates state for input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // updates state for number changes in input
  const handleNumChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value === "" ? null : value,
    }));
  };

  // Submits the form data to the backend, calls fetchAndUpdateFleet to update data
  const handleSubmit = async (event) => {
    event.preventDefault();
    const userId = cityInfo.id; // Assuming userId is available in cityInfo
    console.log(userId);
    console.log(formData);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ROUTE}api/city-info/patch/${userId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: useAuthStore.getState().getToken(),
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update data");
      }

      await response.json();
      toast({
        title: "Success",
        description:`City information has been saved`,
      });
      fetchAndUpdateFleet();
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid gap-6">
        {/* Basic Information */}
        <div className="space-y-4">
          <h3 className="text-lg font-medium">Basic Information</h3>
          <div className="grid gap-4 sm:grid-cols-2">
            <div className="space-y-2">
              <Label>Recovery Email:</Label>
              <Input
                type="text"
                name="recovery_email"
                value={formData.recovery_email}
                onChange={handleInputChange}
              />
            </div>
            <div className="space-y-2">
              <Label>City Name:</Label>
              <Input
                type="text"
                name="city_name"
                value={formData.city_name}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="space-y-2">
            <Label>City Image URL:</Label>
            <Input
              type="text"
              name="city_image"
              value={formData.city_image}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <Separator />

        {/* Graph Settings */}
        <div className="space-y-4">
          <h3 className="text-lg font-medium">Graph Settings</h3>
          <div className="grid gap-4 sm:grid-cols-2">
            <div className="space-y-2">
              <Label>Cost Benefit Analysis Graph Min:</Label>
              <Input
                type="number"
                name="cost_benefit_min"
                value={formData.cost_benefit_min}
                onChange={handleNumChange}
              />
            </div>
            <div className="space-y-2">
              <Label>Cost Benefit Analysis Graph Max:</Label>
              <Input
                type="number"
                name="cost_benefit_max"
                value={formData.cost_benefit_max}
                onChange={handleNumChange}
              />
            </div>
            <div className="space-y-2">
              <Label>Cost and Savings Graph Max:</Label>
              <Input
                type="number"
                name="cost_savings_max"
                value={formData.cost_savings_max}
                onChange={handleNumChange}
              />
            </div>
            <div className="space-y-2">
              <Label>Capital Costs Graph Max:</Label>
              <Input
                type="number"
                name="capital_costs_max"
                value={formData.capital_costs_max}
                onChange={handleNumChange}
              />
            </div>
          </div>
        </div>

        <Separator />

        {/* Year Range Settings */}
        <div className="space-y-4">
          <h3 className="text-lg font-medium">Year Range Settings</h3>
          <div className="grid gap-4 sm:grid-cols-2">
            {/* <div className="space-y-2">
              <Label>Minimum Year:</Label>
              <Input
                type="number"
                name="year_min"
                value={formData.year_min}
                onChange={handleNumChange}
              />
            </div> */}
            <div className="space-y-2">
              <Label>Maximum Year:</Label>
              <Input
                type="number"
                name="year_max"
                value={formData.year_max}
                onChange={handleNumChange}
              />
            </div>
          </div>
        </div>

        <Separator />

        {/* Range Settings */}
        <div className="space-y-4">
          <h3 className="text-lg font-medium">Range Settings</h3>
          <div className="grid gap-4 sm:grid-cols-2">
            <div className="space-y-2">
              <Label>Range 1:</Label>
              <Input
                type="text"
                name="range_1"
                value={formData.range_1}
                onChange={handleInputChange}
              />
            </div>
            <div className="space-y-2">
              <Label>Range 2:</Label>
              <Input
                type="text"
                name="range_2"
                value={formData.range_2}
                onChange={handleInputChange}
              />
            </div>
            <div className="space-y-2">
              <Label>Range 3:</Label>
              <Input
                type="text"
                name="range_3"
                value={formData.range_3}
                onChange={handleInputChange}
              />
            </div>
            <div className="space-y-2">
              <Label>Range 4:</Label>
              <Input
                type="text"
                name="range_4"
                value={formData.range_4}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>

        <div className="flex justify-end">
          <Button type="submit">
            Update
          </Button>
        </div>
      </div>
    </form>
  );
};

export default CityInfoForm;
