/**
 * @fileoverview This is the component for the economics section of the advanced controls.
 */

import React, { forwardRef, useState } from "react";
import { useEffect } from "react";
import { Input } from "components/ui/input";
import { Switch } from "components/ui/switch";
import ControlLabel from "./ControlLabel";
import { NumericFormat } from "react-number-format";
import useAuthStore from "store/useAuthStore";
import { useUpdateAdvancedControl } from "./advUtils";
import useProForma from "store/useProForma";
import useCache from "store/useCache";

const Economics = forwardRef((props, ref) => {
  const updateAdvancedControl = useUpdateAdvancedControl();
  const { advancedControls, controls } = useProForma();
  const [incentives, setIncentives] = useState(false);
  const [iraIncentives, setIraIncentives] = useState(false);
  const [evseIncentives, setEvseIncentives] = useState(false);

  const { updateControls } = useCache();

  const { user } = useAuthStore();
  const [loaded, setLoaded] = useState(false);
  const [formState, setFormState] = useState({
    inflation: false,
    inflation_escalation_rate: "",
    electricity_escalation_rate: "",
    gasoline_escalation_rate: "",
    infrastructure_loan_term: "",
    infrastructure_loan_interest_rate: "",
    discount_rate_npv: "",
    maintenance_costs_annual_per_station: "",
  });

  useEffect(() => {
    if (controls.domiciles) {
      setIncentives(controls.incentives || false);
      setIraIncentives(controls.ira_incentives || false);
      setEvseIncentives(controls.evse_incentives || false);
    }
  }, [controls]);

  useEffect(() => {
    if (advancedControls) {
      if (advancedControls?.id) {
        setLoaded(true);
      }

      setFormState((prevState) => {
        const newState = {
          inflation: advancedControls.inflation ?? false,
          inflation_escalation_rate:
            advancedControls.inflation_escalation_rate ?? "",
          electricity_escalation_rate:
            advancedControls.electricity_escalation_rate ?? "",
          gasoline_escalation_rate:
            advancedControls.gasoline_escalation_rate ?? "",
          infrastructure_loan_term:
            advancedControls.infrastructure_loan_term ?? "",
          infrastructure_loan_interest_rate:
            advancedControls.infrastructure_loan_interest_rate ?? "",
          discount_rate_npv: advancedControls.discount_rate_npv ?? "",
          maintenance_costs_annual_per_station:
            advancedControls.maintenance_costs_annual_per_station ?? "",
        };

        // Only update state if there's a difference
        if (JSON.stringify(newState) !== JSON.stringify(prevState)) {
          return newState;
        } else {
          return prevState;
        }
      });
    }
  }, [advancedControls]);

  const handleChange = async (e) => {
    const { name, value, type, checked } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
    try {
      let val = value;
      if (val.endsWith("%")) {
        val = val.slice(0, -1);
      }
      val = Number(val);
      await updateAdvancedControl(user.id, { [name]: val });
    } catch (error) {
      // Handle the error if needed
    }
  };

  const handleChange1 = async (e) => {
    const { name, value, type, checked } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSwitchChange = async (checked) => {
    setFormState((prevState) => ({
      ...prevState,
      inflation: checked,
    }));

    try {
      await updateAdvancedControl(user.id, { inflation: checked });
    } catch (error) {
      // Handle the error if needed
      console.error("Error updating advanced control:", error);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const updateControl = async (attribute, value) => {
    if (value === "" || value === null) return;
    updateControls(attribute, value);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ROUTE}api/controls/${user.id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: useAuthStore.getState().getToken(),
          },
          body: JSON.stringify({ attribute, value }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      await response.json();
    } catch (error) {
      console.error(`Error updating control: ${error.message}`);
    }
  };

  const handleIncentivesChange = (checked) => {
    const newValue = checked;
    setIncentives(newValue);
    updateControl("incentives", newValue);
    // Call additional function here
  };

  const handleIraIncentivesChange = (checked) => {
    const newValue = checked;
    setIraIncentives(newValue);
    updateControl("ira_incentives", newValue);
  };

  const handleEvseIncentivesChange = (checked) => {
    const newValue = checked;
    setEvseIncentives(newValue);
    updateControl("evse_incentives", newValue);
  };

  return (
    <div className="w-full">
      <form ref={ref} onSubmit={handleSubmit}>
        <div className="grid w-full items-center gap-2">
          <div className="flex w-full items-center justify-between">
            <ControlLabel text="State/Local EV Incentives" info="" />
            <Switch
              checked={incentives}
              onCheckedChange={handleIncentivesChange}
            />
          </div>
          <div className="flex w-full items-center justify-between">
            <ControlLabel text="Federal EV Incentives" info="" />
            <Switch
              checked={iraIncentives}
              onCheckedChange={handleIraIncentivesChange}
            />
          </div>
          {/* <div className="flex w-full items-center justify-between">
            <ControlLabel text="EVSE Incentives" info="" />
            <Switch
              checked={evseIncentives}
              onCheckedChange={handleEvseIncentivesChange}
            />
          </div> */}
          <div className="flex w-full items-center justify-between">
            <ControlLabel text="Inflation" info="" />
            <Switch
              checked={formState.inflation}
              onCheckedChange={handleSwitchChange}
            />
            <input
              type="hidden"
              name="inflation"
              value={formState.inflation ? "true" : "false"}
            />
          </div>
          <div className="flex w-full items-center justify-between">
            <div className="flex">
              <ControlLabel
                text="Inflation Escalation Rate"
                info="Controls the escalation rate of inflation for EV purchase cost, Default Replacement Allocation, and Annual Maintenance Costs "
              />
            </div>
            <NumericFormat
              customInput={Input}
              variant="blank"
              name="inflation_escalation_rate"
              value={formState.inflation_escalation_rate}
              onBlur={(e) =>
                handleChange({
                  target: {
                    name: "inflation_escalation_rate",
                    value: e.target.value,
                    type: "text",
                  },
                })
              }
              thousandSeparator={true}
              decimalScale={2}
              allowNegative={false}
              suffix="%"
              isAllowed={(values) => {
                const { floatValue } = values;
                return (
                  floatValue === undefined ||
                  (floatValue >= 0 && floatValue <= 100)
                );
              }}
              placeholder="%"
            />
          </div>
          <div className="flex w-full items-center justify-between">
            <ControlLabel
              text="Electricity Escalation Rate"
              info="Controls the escalation rate of inflation for EV Annual Charging Costs"
            />
            <NumericFormat
              customInput={Input}
              variant="blank"
              name="electricity_escalation_rate"
              value={formState.electricity_escalation_rate}
              onBlur={(e) =>
                handleChange({
                  target: {
                    name: "electricity_escalation_rate",
                    value: e.target.value,
                    type: "text",
                  },
                })
              }
              thousandSeparator={true}
              decimalScale={2}
              allowNegative={false}
              suffix="%"
              isAllowed={(values) => {
                const { floatValue } = values;
                return (
                  floatValue === undefined ||
                  (floatValue >= 0 && floatValue <= 100)
                );
              }}
              placeholder="%"
            />
          </div>
          <div className="flex w-full items-center justify-between">
            <ControlLabel
              text="Gasoline Escalation Rate"
              info="Controls the escalation rate of inflation for Existing Vehicle Annual Fuel Costs"
            />
            <NumericFormat
              customInput={Input}
              variant="blank"
              name="gasoline_escalation_rate"
              value={formState.gasoline_escalation_rate}
              onBlur={(e) =>
                handleChange({
                  target: {
                    name: "gasoline_escalation_rate",
                    value: e.target.value,
                    type: "text",
                  },
                })
              }
              thousandSeparator={true}
              decimalScale={2}
              allowNegative={false}
              suffix="%"
              isAllowed={(values) => {
                const { floatValue } = values;
                return (
                  floatValue === undefined ||
                  (floatValue >= 0 && floatValue <= 100)
                );
              }}
              placeholder="%"
            />
          </div>
          <div className="flex w-full items-center justify-between">
            <ControlLabel
              text="Infrastructure Loan Term (yrs)"
              info="Controls the length of of the loan term"
            />
            <Input
              variant="blank"
              name="infrastructure_loan_term"
              value={formState.infrastructure_loan_term}
              onBlur={(e) =>
                handleChange({
                  target: {
                    name: "infrastructure_loan_term",
                    value: e.target.value,
                    type: "text",
                  },
                })
              }
              onChange={handleChange1}
              type="text"
              placeholder=""
            />
          </div>
          <div className="flex w-full items-center justify-between">
            <ControlLabel
              text="Infrastructure Loan Interest Rate"
              info="Controls the interest rate of the loan"
            />
            <NumericFormat
              customInput={Input}
              variant="blank"
              name="infrastructure_loan_interest_rate"
              value={formState.infrastructure_loan_interest_rate}
              onBlur={(e) =>
                handleChange({
                  target: {
                    name: "infrastructure_loan_interest_rate",
                    value: e.target.value,
                    type: "text",
                  },
                })
              }
              thousandSeparator={true}
              decimalScale={2}
              allowNegative={false}
              suffix="%"
              isAllowed={(values) => {
                const { floatValue } = values;
                return (
                  floatValue === undefined ||
                  (floatValue >= 0 && floatValue <= 100)
                );
              }}
              placeholder="%"
            />
          </div>
          <div className="flex w-full items-center justify-between">
            <ControlLabel
              text="Discount Rate  (NPV)"
              info="Controls discount rate for calculating the Net Present Value (NPV)"
            />
            <NumericFormat
              customInput={Input}
              variant="blank"
              name="discount_rate_npv"
              value={formState.discount_rate_npv}
              onBlur={(e) =>
                handleChange({
                  target: {
                    name: "discount_rate_npv",
                    value: e.target.value,
                    type: "text",
                  },
                })
              }
              thousandSeparator={true}
              decimalScale={2}
              allowNegative={false}
              suffix="%"
              isAllowed={(values) => {
                const { floatValue } = values;
                return (
                  floatValue === undefined ||
                  (floatValue >= 0 && floatValue <= 100)
                );
              }}
              placeholder="%"
            />
          </div>
          <div className="flex w-full items-center justify-between">
            <ControlLabel
              text="Charger Maintenance Costs  ($/yr)"
              info="Cost of maintenance, annual per station"
            />
            <Input
              variant="blank"
              name="maintenance_costs_annual_per_station"
              value={formState.maintenance_costs_annual_per_station}
              onBlur={(e) =>
                handleChange({
                  target: {
                    name: "maintenance_costs_annual_per_station",
                    value: e.target.value,
                    type: "text",
                  },
                })
              }
              onChange={handleChange1}
              type="text"
              placeholder=""
            />
          </div>
        </div>
      </form>
    </div>
  );
});

Economics.displayName = "Economics";

export default Economics;
